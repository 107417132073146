<template>
  <div style="height: calc(100vh - 40px); overflow-y: auto">
    <el-form :inline="true" :model="searchForm">
      <div class="row-bg">
        <el-row :gutter="24">
          <el-col :lg="5" :md="24">
            <el-form-item label="类型名称：">
              <el-input
                clearable
                v-model="searchForm.name"
                placeholder="请输入"
                class="w100"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :lg="5" :md="24">
            <el-form-item label="分类：">
              <el-select
                clearable
                v-model="searchForm.group"
                placeholder="分类"
                class="w100"
              >
                <el-option
                  v-for="item in options"
                  :key="item.key"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <span>
            <el-button @click="resetForm">重置</el-button>
            <el-button type="primary" @click="getListHandler">查询</el-button>
          </span>
        </el-row>
      </div>
    </el-form>
    <!-- 表格 -->
    <el-card
      shadow="never"
      style="overflow-y: scroll; height: calc(100% - 80px)"
    >
      <div slot="header" class="flex" style="align-items: center">
        <span>类型数据</span>
        <div class="flex_1"></div>
        <!-- <el-button type="primary" size="mini" @click="openAddModal"
          >添加
        </el-button> -->
        <text-button
          icon="el-icon-refresh"
          contentTit="刷新"
          @click.native="search"
        ></text-button>
        <text-button
          icon="el-icon-setting"
          contentTit="列设置"
          :colSetting="colSetting"
          @update="update"
          :ispopover="true"
          :baseColSetting="baseColSetting"
        ></text-button>
      </div>

      <el-table
        :default-sort="{ prop: 'open_date', order: 'descending' }"
        :key="tableKey"
        fit
        :highlight-current-row="true"
        :data="tableData"
      >
        <el-table-column
          v-for="item of defaultHeader"
          :key="item.label"
          :label="item.label"
          :align="item.align ? item.align : 'left'"
          :min-width="item.width ? item.width : 'auto'"
          :sortable="item.sortable ? item.sortable : false"
          :prop="item.prop"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            <template v-if="item.label === '状态'">
              <span
                class="span-state"
                :style="
                  'backgroundColor' + ':' + getAccountColor(scope.row.status)
                "
              ></span>
              <span
                :style="'color' + ':' + getAccountColor(scope.row.status)"
                >{{ scope.row.status }}</span
              >
            </template>
            <template v-else-if="item.label === '创建时间'">
              <span v-if="scope.row.createTime">
                {{ scope.row.createTime.substring(0, 19) }}</span
              >
              <span v-else>--</span>
            </template>
            <template v-else-if="item.label === '操作'">
              <el-button
                style="margin-left: 8px"
                type="text"
                @click="editIdentityModal(scope.row)"
                >管理权限
              </el-button>
            </template>
            <span v-else>{{ scope.row[item.prop] }}</span>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination
        :size="size"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageInfo.pageNum"
        :page-sizes="[10, 20, 30, 50, 100]"
        :page-size="pageInfo.pageSize"
        :total="pageInfo.total"
        layout="total, sizes, prev, pager, next, jumper"
        background
      >
      </el-pagination>
    </el-card>

    <add-identity
      :treeList="treeList"
      :show="identityDialog"
      v-if="identityDialog"
      @close="
        identityDialog = false;
        identityData = null;
      "
      :identityData="identityData"
      @addIdentity="addIdentity"
      @editIdentity="editIdentity"
    ></add-identity>
  </div>
</template>
<script>
import getAccountColor from "@/mixins/getStateColor";
import addIdentity from "@/components/system/addIdentity";
import TextButton from "@/components/TextButton";
// 默认展示列
import {
  getIdentityList,
  getRoleListAll,
  updateIdentityRole,
} from "@/api/system/userType";
import { getSystemList } from "@/api/system/role";

const baseColSetting = [
  {
    label: "用户类型",
    prop: "name",
    state: true,
    align: "center",
    width: "150px",
  },
  {
    label: "分类",
    prop: "group",
    state: true,
    align: "center",
    width: "150",
  },
  {
    label: "创建时间",
    prop: "createTime",
    state: true,
    sortable: true,
    align: "center",
    width: "150",
  },
  {
    label: "操作",
    state: true,
    align: "center",
    width: "150",
  },
];
export default {
  mixins: [getAccountColor],
  components: {addIdentity,TextButton },
  data() {
    return {
      treeList: [],
      identityData: [],
      identityDialog: false,
      tableKey: 1,
      item: {},
      title: "",
      size: "small",
      tableData: [],
      systemList: [],
      pageInfo: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
        needCount: true,
      },
      searchForm: {
        name: null,
        group: null,
      },
      options: [
        {
          value: "教务管理平台",
          label: "教务管理平台",
        },
        {
          value: "加盟校管理平台",
          label: "加盟校管理平台",
        },
      ],
      colSetting: JSON.parse(JSON.stringify(baseColSetting)), // 表格展示列
      baseColSetting: JSON.parse(JSON.stringify(baseColSetting)),
    };
  },
  created() {
    this.getListHandler();
    const sort = [
      'cms',
      'merchant',
      'education',
      'learn',
      'train',
    ];
    getSystemList({}).then((res) => {
      let arr = [];
      this.systemList = res.body;
      res.body.map((item, index) => {
        getRoleListAll({ systemId: item.id }).then((ress) => {
          let num = sort.indexOf(item.systemCode);
          num = num>-1?num:4
          arr[num] = {
            ...item,
            name: item.systemName,
            children:
              ress.body.length > 0
                ? ress.body.map((i) => {
                    return {
                      ...i,
                      name: i.name + "_" + i.description,
                    };
                  })
                : [],
          };
          if (index === this.systemList.length - 1) {
            this.treeList = arr;
          }
        });
      });
    });
  },
  mounted() {},
  computed: {
    defaultHeader() {
      return this.colSetting.filter((item) => item.state);
    },
  },
  methods: {
    // 添加TODO
    addIdentity() {},
    // 编辑
    editIdentity(data) {
      console.log(data);
      updateIdentityRole({
        identityId: data.identityId, //	是	String	用户类型id
        roleIds: data.roleIds, //
      }).then((res) => {
        console.log(res);
        this.identityDialog = false;
        this.$message.success("修改成功！");
        this.getListHandler();
      });
    },

    // 编辑类型权限
    editIdentityModal(val) {
      this.identityData = val;
      this.$nextTick(() => {
        this.identityDialog = true;
      });
    },
    /**
     * 获取列表
     */

    async getListHandler() {
      let responseData = await getIdentityList({
        pageNum: this.pageInfo.pageNum,
        pageSize: this.pageInfo.pageSize,
        needCount: this.pageInfo.needCount,
        ...this.searchForm,
      });
      if (responseData && responseData.state == "success") {
        this.tableData = responseData.body.list;
        this.pageInfo.total = responseData.body.total;
      }
    },
   
   
    /**
     * 重置表单
     */
    resetForm() {
      this.pageInfo.pageNum = 1;
      this.pageInfo.pageSize = 10;
      this.searchForm = {
        name: null,
        group: null,
      };
      this.getListHandler();
    },
    /**
     * 页码改变
     */
    handleCurrentChange(page) {
      this.pageInfo.pageNum = page;
      this.getListHandler();
    },
    /**
     * 刷新
     */
    search() {
      this.pageInfo.pageNum = 1;
      this.getListHandler();
    },
    update(data) {
      this.tableKey = this.tableKey + 1;
      if (data.lenght !== 0) {
        this.colSetting.forEach((item) => {
          item.state = false;
          data.forEach((ele) => {
            if (ele === item.label && !item.state) {
              item.state = true;
            }
          });
        });
      } else {
        this.colSetting = [];
      }
    },
    /**
     * pageSize改变
     */
    handleSizeChange(size) {
      this.pageInfo.pageSize = size;
      this.getListHandler();
    },
  },
};
</script>
<style lang="scss" scoped>
.outer-style {
  height: calc(100% - 50px);
  overflow: auto;
}

@import "@/style/container.scss";

/deep/ .el-form--inline .el-form-item {
  margin-right: 0;
}
</style>

