var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.show,
        "before-close": _vm.close,
        "close-on-click-modal": false,
        width: "60%",
        top: "7vh",
      },
      on: {
        "update:visible": function ($event) {
          _vm.show = $event
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "dia-tit", attrs: { slot: "title" }, slot: "title" },
        [
          _c("i"),
          _c("span", {
            domProps: { textContent: _vm._s(_vm.identityDialogTitle) },
          }),
        ]
      ),
      _c(
        "el-form",
        {
          ref: "formData",
          attrs: {
            model: _vm.formData,
            rules: _vm.rules,
            "label-width": "50px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { prop: "roleIds" } },
            [
              _c("el-tree", {
                ref: "tree",
                staticStyle: { height: "300px", "overflow-y": "auto" },
                attrs: {
                  data: _vm.treeList,
                  "show-checkbox": "",
                  "default-expand-all": "",
                  "node-key": "id",
                  "highlight-current": "",
                  props: _vm.defaultProps,
                },
                model: {
                  value: _vm.formData.roleIds,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "roleIds", $$v)
                  },
                  expression: "formData.roleIds",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              staticClass: "dialog-btn",
              attrs: { type: "primary" },
              on: { click: _vm.saveIdentityForm },
            },
            [_vm._v("确 定")]
          ),
          _c(
            "el-button",
            {
              staticClass: "dialog-btn",
              attrs: { type: "info" },
              on: { click: _vm.close },
            },
            [_vm._v("取 消")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }