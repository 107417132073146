<template>
  <el-dialog
    :visible.sync="show"
    :before-close="close"
    :close-on-click-modal="false"
    width="60%"
    top="7vh"
  >
    <div slot="title" class="dia-tit">
      <i></i>
      <span v-text="identityDialogTitle"></span>
    </div>
    <el-form ref="formData" :model="formData" :rules="rules" label-width="50px">
      <el-form-item prop="roleIds">
        <el-tree
          v-model="formData.roleIds"
          :data="treeList"
          show-checkbox
          default-expand-all
          node-key="id"
          ref="tree"
          highlight-current
          :props="defaultProps"
          style="height: 300px; overflow-y: auto"
        >
        </el-tree>
      </el-form-item>
    </el-form>
    <el-row slot="footer">
      <el-button type="primary" @click="saveIdentityForm" class="dialog-btn"
        >确 定</el-button
      >
      <el-button @click="close" type="info" class="dialog-btn">取 消</el-button>
    </el-row>
  </el-dialog>
</template>
<script>
import { getRoleListByIdentity } from "@/api/system/userType";
export default {
  props: {
    show: {
      default: false,
      type: Boolean,
    },
    identityData: {
      default: () => {
        return {};
      },
      type: Object,
    },
    treeList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      identityDialogTitle: "",
      formData: {
        state: "E",
      },
      systemMenus: [],
      defaultProps: {
        children: "children",
        label: "name",
      },
      menuIds: [],
      rules: {
        roleIds: [
          { required: false, message: "角色不可为空", trigger: "blur" },
        ],
      },
    };
  },
  mounted() {
    if (this.identityData) {
      this.identityDialogTitle = "编辑类型权限";
      this.formData = { ...this.identityData };
      this.$nextTick(() => {
        this.initData({ ...this.identityData });
      });
    } else {
      this.identityDialogTitle = "新增类型权限";
    }
  },
  methods: {
    initData(val) {
      getRoleListByIdentity({ identityId: val.id }).then((res) => {
        if (this.$refs.tree) {
          this.$refs.tree.setCheckedKeys(res.body.map((i) => i.id));
        }
      });
    },
    // 关闭
    close() {
      this.$emit("close");
    },
    saveIdentityForm() {
      this.$refs.formData.validate((valid) => {
        if (valid) {
          if (this.$refs.tree.getCheckedKeys().length == 0) {
            this.$message.info("角色不可为空！");
            return;
          }
          this.$nextTick(() => {
            if (this.roleData === null) {
              this.$emit("addIdentity", this.formData);
            } else {
              this.$emit("editIdentity", {
                roleIds: this.$refs.tree.getCheckedKeys(true),
                identityId: this.formData.id,
              });
            }
          });
        }
      });
    },

    // 备用
    getCheckedNodes() {
      console.log(this.$refs.tree.getCheckedNodes());
    },
    getCheckedKeys() {
      console.log(this.$refs.tree.getCheckedKeys());
    },
    setCheckedNodes() {
      this.$refs.tree.setCheckedNodes([]);
    },
    setCheckedKeys() {
      this.$refs.tree.setCheckedKeys([]);
    },
    resetChecked() {
      this.$refs.tree.setCheckedKeys([]);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/style/dialog.scss";
</style>
